<template>
  <div></div>
</template>

<script setup lang="ts">
import type { AddressFieldsValues } from '@backmarket/http-api/src/api-specs-shipping/address/address-validation.types'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { watchDebounced } from '@vueuse/core'

import type { LoggerScope } from '../../types/fieldsValidator'

const logger = useLogger()

/**
 * This component is used to log the errors from the AddressFields component on datadog
 * You can find the datadog dashboard here: https://app.datadoghq.com/dashboard/vct-c43-473/addressvalidation-form-errors
 */

interface Props {
  /**
   * The form values, it is used to log the values on datadog
   */
  formValues: AddressFieldsValues
  /**
   * The errors object, it is used to log the errors on datadog
   */
  errors: Record<keyof AddressFieldsValues, string>
  /**
   * The logger scope, it is used to sort the logs on datadog
   */
  loggerScope: LoggerScope
}
const DEBOUNCE_VALUE = 1000

const props = defineProps<Props>()

watchDebounced(
  () => props.errors,
  (newErrors) => {
    // We are logging only the fields that are handled by dynamic address field validators
    const FIELDS_TO_LOG = [
      'city',
      'company',
      'country',
      'postalCode',
      'street',
      'street2',
    ]
    /**
     * We are logging the errors only if there is a value in the field to avoid logging empty values or boolean values
     * We do not log the password field value
     */
    const mappedErrors = (
      Object.keys(newErrors) as Array<keyof AddressFieldsValues>
    )
      .map((key) => ({
        error: newErrors[key],
        value: props.formValues[key]?.toString(),
        fieldName: key,
        // We are adding the stateOrProvince to the error details to improve postalCode rules
        ...insertIf(key === 'postalCode', {
          city: props.formValues.city,
          stateOrProvince: props.formValues.stateOrProvince,
          country: props.formValues.country,
        }),
      }))
      .filter((error) => error.value && FIELDS_TO_LOG.includes(error.fieldName))

    if (!isEmpty(mappedErrors)) {
      logger.info(
        `[SHIPPING][AddressValidationError][${props.loggerScope}] Errors triggered from address form`,
        {
          errors: mappedErrors,
          formName: props.loggerScope,
        },
      )
    }
  },
  { deep: true, debounce: DEBOUNCE_VALUE },
)
</script>
